@import "../../styles/_variables";

.new-package {
  position: relative;
  z-index: 0;
  max-height: calc(100% - 54px);
  overflow: auto;

  &__view {
    display: flex;
    flex-direction: column;
    padding: 0 48px 75px;
    width: 890px;
    max-width: 890px;
    height: 100%;

    &__form {
      height: auto;
    }
  }

  &-to {
    margin: 28px 0;
    font-size: 16px;

    &__approver {
      margin-bottom: 2px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      color: #000000;
      letter-spacing: normal;
      opacity: 0.4;
      font-stretch: normal;
    }
  }

  &__exetutor {
    position: relative;
    z-index: 1000;
  }
  
  &__radio-group {
    margin-bottom: 24px;
  }
}

@import "../../styles/variables";

.language-switcher {
  display: flex;
  margin: 8px 0;

  &__lang {
    margin-right: 10px;
    color: $grey-normal;
    cursor: pointer;

    &.is-active {
      color: $black;
    }
  }
}
.familiarization {
  display: flex;
  height: 100%;

  &__list {
  }

  &__view {
    flex-grow: 1;
    height: 100%;
    max-height: 100%;
    overflow: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 22px 0;
    height: 50px;
    min-height: 50px;
  }

  &__title {
    font-family: SFProDisplay;
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
    color: #333333;
    letter-spacing: normal;
  }

  &__searcher {
    display: flex;
    justify-content: space-between;
    padding: 25px 22px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &__search {
      flex-grow: 1;
    }

    &__filter {
      margin-left: 16px;
    }
  }

  &__tabs {
    padding: 0 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}
.auth-welcome {

  &__avatar {
    margin: 57px 0 51px;
  }

  &__text {
    margin-bottom: 6px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.11;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.5px;
    text-align: center;
    opacity: 0.4;
    font-stretch: normal;
  }

  &__name {
    flex: 1;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: bold;
    line-height: 1.37;
    font-size: 30px;
    color: #000000;
    letter-spacing: 0.5px;
    text-align: center;
    font-stretch: normal;

    & + .auth__bottom {
      display: none;
    }
  }
}
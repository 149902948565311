@import "../../../styles/variables";

.auth-phone {

  &__persacc {
    margin: 30px 0 10px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 30px;
    letter-spacing: normal;
    text-align: center;
    font-stretch: normal;
  }

  &__phonerequest {
    margin-bottom: 52px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 18px;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.4;
    font-stretch: normal;
  }

  &__code {
    margin: 8px 0 23px;
  }

  &__number {
    position: relative;
    margin-bottom: 52px;
    max-width: 270px;

    &_input {

      &.form-control {
        width: 100%;
        font-size: 22px;
        border: none;
        border-bottom: 1px solid #244A68;
        border-radius: 0;
        box-shadow: none;
        outline: 0;
      }

      &.form-control:focus {
        border: none;
        border-bottom: 2px solid #244A68;
        box-shadow: none;
      }

      &.form-control + div:before {
        content: none;
      }
    }

    .react-tel-input .country-list .country {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 17px;
      color: #222423;
    }

    .react-tel-input .country-list {
      border-radius: 0;
      box-shadow: 0 7px 32px -9px rgba(0, 0, 0, 0.218313);
    }

    .react-tel-input .country-list .country .dial-code {
      color: #999999;
    }
  }

  &__entry {
    display: flex;
    flex: 1;
    align-items: center;
  }

  &__button {
    width: 240px;
  }

  &__error {
    position: absolute;
    bottom: -24px;
    left: 50%;
    width: 200px;
    font-size: 12px;
    color: $red;
    text-align: center;
    transform: translate3d(-50%, 0, 0);
  }
}
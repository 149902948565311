@import "../../styles/variables";

.menu {
  display: flex;
  flex-direction: column;
  width: 79px;
  min-width: 79px;
  background-color: #111d26;

  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 72px;
    transition: 250ms opacity;

    &:hover {
      cursor: pointer;

      .menu__image, .menu__text {
        opacity: 1;
      }
    }

    &.--active {
      background-color: #212c35;

      .menu__image, .menu__text {
        opacity: 1;
      }
    }

    &.--empty {
      opacity: 0.5;
    }
  }

  &__logo {
    background-image: url("../../images/logo.svg");
    background-position: center center;
    background-size: 32px 32px;
    background-repeat: no-repeat;
    opacity: 1;
  }

  &__image {
    width: 28px;
    height: 28px;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.5;

    &.--documents {
      background-image: url("../../images/documents.svg");
    }

    &.--agreement {
      background-image: url("../../images/agreement.svg");
    }

    &.--members {
      background-image: url("../../images/members.svg");
    }

    &.--familiarization {
      background-image: url("../../images/familiarization.svg");
    }

    &.--inbox {
      background-image: url("../../images/inbox.svg");
    }

    &.--outgoing {
      background-image: url("../../images/outgoing.svg");
    }

    &.--question {
      background-image: url("../../images/question.svg");
    }
  }

  &__text {
    font-family: SFProText;
    font-weight: 700;
    font-size: 10px;
    color: #f3f3f3;
    letter-spacing: -0.2px;
    opacity: 0.5;
  }

  &__question {
    margin-top: auto;
    margin-bottom: 40px;
  }

  &__avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  &__not_viewed {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    min-width: 18px;
    height: 18px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 13px;
    color: #f3f3f3;
    letter-spacing: 0.2px;
    text-align: center;
    background-color: $red;
    border-radius: 18px;
    font-stretch: normal;
  }
}

.searcher {
  position: relative;
  height: 32px;

  input[type="text"] {
    padding-left: 32px;
    width: 100%;
    height: 100%;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 15px;
    letter-spacing: 0.2px;
    background-color: #eaeaea;
    border: none;
    border-radius: 5px;
    outline: none;
    font-stretch: normal;

    &:focus {
      background-color: #efefef;
    }

    &::placeholder {
      color: #a1a6a3;
    }
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 8px;
    display: block;
    margin-top: -9px;
    width: 19px;
    height: 19px;
    background-image: url("../../images/loop.svg");
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
  }
}

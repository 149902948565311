.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 17px;
  color: #016fe6;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  background-color: #ebecec;
  border-radius: 100%;
  font-stretch: normal;
  align-self: flex-start;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
}

@import "../../styles/_variables";

.request-view {
  position: relative;
  height: 100%;

  &__container {
    max-height: 100%;
    overflow: auto;

    &:after {
      content: "";
      display: block;
      height: 103px;
    }

    &.--full {

      &:after {
        height: 28px;
      }
    }
  }

  &__loader {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &__actions {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 98px;
    height: 75px;
    background-color: #ffffff;
    box-shadow: 1px -3px 4px 0 rgba(0, 0, 0, 0.11);

    .button + .button {
      margin-left: 10px;
    }

    &_reject {
      display: flex;
      align-items: center;
      padding-right: 8px;
      font-weight: bold;

      &:before {
        display: block;
        margin-right: 8px;
        width: 32px;
        height: 32px;
        background-image: url("../../images/reject.svg");
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
      }
    }

    &_chat {
      margin: 0 10px;
      font-weight: bold;

      $radius: 18px;

      &__amount {
        position: absolute;
        z-index: 10;
        top: -8px;
        right: -8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        min-width: $radius;
        height: $radius;
        font-family: SFProDisplay;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-size: 13px;
        color: #f3f3f3;
        letter-spacing: 0.2px;
        text-align: center;
        background-color: $red;
        border-radius: $radius;
        font-stretch: normal;
      }

      &__text {
        display: flex;
        align-items: center;
        font-weight: bold;

        &:before {
          display: block;
          margin-right: 14px;
          width: 21px;
          height: 20px;
          background-image: url("../../images/bubble-contrast.svg");
          background-size: contain;
          background-repeat: no-repeat;
          content: "";
        }
      }
    }

    &_file.--action {
      margin-left: 24px;
    }

    &_approve {
      display: flex;
      align-items: center;
      padding-right: 8px;
      font-weight: bold;

      &:before {
        display: block;
        margin-right: 8px;
        width: 32px;
        height: 32px;
        background-image: url("../../images/approve.svg");
        background-size: contain;
        background-repeat: no-repeat;
        content: "";
      }
    }

    &_edit {
      display: flex;
      align-items: center;
      font-weight: bold;
    }
  }

  &__watchers {
    margin-left: 100px;
    width: 714px;
    max-width: 714px;
  }

  &__discussion {
    margin-top: 56px;
    margin-left: 100px;
    width: 714px;
    max-width: 714px;

    &_header {
      margin-top: 32px;
      margin-bottom: 8px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 20px;
      letter-spacing: normal;
      font-stretch: normal;
    }
  }

  &__agreement_list {
    margin: 48px 0 0 100px;
    width: 714px;
    max-width: 714px;

    &_header {
      margin-bottom: 16px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 20px;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &_file {
      display: flex;
      align-items: center;
      padding: 12px;
      font-size: 14px;
      color: #333333;
      text-decoration: none;
      background-color: #f7f7f7;

      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        text-decoration: none;
      }
    }

    a.request-view__agreement_list_dl {
      margin-left: auto;
      padding: 12px;
      color: $cerulean-blue;
    }

    .request-view__agreement_list_actions {
      margin-left: auto;
      padding: 12px;
      color: $cerulean-blue;
      display: grid;
      align-content: center;
      justify-items: center;
      gap: 20px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &_text {
      margin-left: 4px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      font-stretch: normal;
    }
  }
}

.request-view-approve {
  width: 667px;

  &__header {
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: 700;
    line-height: 1.12;
    font-size: 34px;
    color: #222423;
    letter-spacing: normal;
    font-stretch: normal;
  }

  &__input {
    margin: 56px 0 32px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 20px;
    letter-spacing: normal;
    font-stretch: normal;
  }

  &__file {
    font-size: 16px;

    input {
      display: none;
    }
  }

  &__list {
    margin-top: 24px;
    padding-right: 16px;
    max-height: 160px;
    overflow-y: auto;
  }

  &__button {
    margin-top: 48px;
  }

  &__actions {
    display: flex;

    .button + .button {
      margin-left: 16px;
    }
  }
}

.update-decision {
  width: 667px;

  &.--warning {
    width: 565px;
  }

  &__header {
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: 700;
    line-height: 1.12;
    font-size: 34px;
    color: #222423;
    letter-spacing: normal;
    font-stretch: normal;
  }

  &__content {
    margin-top: 53px;
    margin-bottom: 62px;
  }

  &__warning_text {
    width: 564px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
    font-size: 20px;
    letter-spacing: normal;
    font-stretch: normal;

    &.--error {
      margin-bottom: 8px;
      color: $red;
    }
  }

  &__input {
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 20px;
    letter-spacing: normal;
    font-stretch: normal;
  }

  &__file {
    font-size: 16px;

    input {
      display: none;
    }
  }

  &__list {
    margin-top: 24px;
    padding-right: 16px;
    max-height: 160px;
    overflow-y: auto;
  }

  &__button {
    margin-top: 48px;
  }

  &__actions {
    display: flex;

    .button + .button {
      margin-left: 16px;
    }
  }

  &__actions_approve {
    display: flex;
    align-items: center;
    padding-right: 8px;
    font-weight: bold;

    &:before {
      display: block;
      margin-right: 8px;
      width: 32px;
      height: 32px;
      background-image: url("../../images/approve.svg");
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }
  }

  &__form {

    &__section_label {
      margin-top: 24px;
      margin-bottom: 24px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 17px;
      color: $black;
      letter-spacing: normal;
      font-stretch: normal;
    }
  }
}
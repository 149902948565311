.auth-pin {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &__text {
    margin: 75px 0 107px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.33;
    font-size: 18px;
    color: #000000;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.4;
    font-stretch: normal;
  }

  & + .auth__bottom {
    display: none;
  }

  &.--error {

    .auth-pin-dot {
      background-color: #ec5a40;
    }
  }

  &__error {
    margin-top: 53px;
    margin-right: 32px;
    margin-left: 32px;
    font-family: SFProDisplay;
    font-size: 16px;
    color: #ec5a40;
    text-align: center;
  }

  &__redirect {
    margin-top: 16px;
    font-family: SFProDisplay;
    font-size: 16px;
    color: #000000;
  }
}
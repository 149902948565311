@import "../../styles/_variables";

$headerHeight: 54px;

.new-request-container {
  position: relative;
  padding: 36px 0 75px;
  height: 100%;
  background-color: #ffffff;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    min-height: $headerHeight;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: bold;
    line-height: 1.21;
    font-size: 34px;
    color: #000000;
    letter-spacing: 0.41px;
    font-stretch: normal;
  }

  &__shutter {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background-image: url("../../images/x.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: content-box;

    &:hover {
      cursor: pointer;
    }
  }

  &__actions {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 48px;
    height: 75px;
    background-color: #ffffff;
    box-shadow: 1px -3px 4px 0 rgba(0, 0, 0, 0.11);
  }

  &__list {
    padding: 25px 22px 0;
    width: 391px;
    height: 100%;
    background-color: #f3f3f3;

    &__title {
      font-family: SFProDisplay;
      font-weight: 600;
      line-height: normal;
      font-size: 20px;
      color: $black;
      letter-spacing: normal;
    }
  }

  &__form {

    &__section_label {
      margin-top: 24px;
      margin-bottom: 24px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 17px;
      color: $black;
      letter-spacing: normal;
      font-stretch: normal;
    }
  }

}

.new-request-layout {
  position: fixed;
  z-index: 4000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
}
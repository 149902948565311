.auth-pin-dots {
  display: flex;
}

.auth-pin-dot {
  width: 14px;
  height: 14px;
  background-color: #d8d8d8;
  border-radius: 50%;

  & + & {
    margin-left: 26px;
  }

  &.--input {
    background-color: #111d26;
  }
}

.auth-pin-input {
  overflow: hidden;
  height: 0;
}
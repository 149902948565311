@import "../../styles/_variables";

.input {
  position: relative;
  display: flex;
  width: 100%;
  height: 48px;

  input[type="text"],
  input[type="password"] {
    margin: 0;
    padding: 0;
    width: inherit;
    font-family: SFProDisplay;
    line-height: 1;
    font-size: inherit;
    border: none;
    outline: none;

    &:focus, &:active {

      & + .input__line {
        background-color: #244a68;
      }
    }
  }

  &__line {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    background-color: #cdd1cf;
    content: "";
  }

  &.--error {

    .input__line {
      background-color: #ec5a40;
    }
  }

  &.--counter {

    input {
      padding-right: 40px;
    }
  }

  &__counter {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 10px;
    color: #a1a6a3;
    transform: translateY(-50%);
  }
}

.input-fl {
  position: relative;
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  height: 48px;
  font-family: SFProDisplay;

  .label {
    position: absolute;
    top: 16px;
    left: 0;
    font-family: SFProDisplay;
    font-size: 16px;
    color: $grey-normal;
    transform-origin: 0 0;
    transition: all 0.2s ease;
  }

  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    color: $grey-normal;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }

  input {
    z-index: 99;
    padding: 8px 0;
    width: 100%;
    height: 43px;
    font-family: SFProDisplay !important;
    font-size: 16px;
    color: $black;
    background: none;
    border: 0;
    border-bottom: 1px solid $grey-normal;
    border-radius: 0;
    -webkit-appearance: none;
    transition: all 0.15s ease;

    &:not(:placeholder-shown) {

      + span {
        color: $grey-normal;
        transform: translateY(-26px) scale(0.75);
      }
    }

    &:focus {
      background: none;
      outline: none;

      + span {
        color: $grey-normal;
        transform: translateY(-26px) scale(0.75);

        + .border {
          transform: scaleX(1)
        }
      }
    }
  }

  &.--error {

    input {
      border-color: #ec5a40;
    }
  }

  &.--counter {

    input {
      padding-right: 46px;
    }
  }

  .counter {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 10px;
    color: #a1a6a3;
    transform: translateY(-50%);
  }
}

@import "../../../styles/variables";

.auth-sms {

  &__header {
    margin: 30px 0 9px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 30px;
    color: #000000;
    letter-spacing: normal;
    text-align: center;
    font-stretch: normal;
  }

  &__text {
    margin-bottom: 56px;
    width: 246px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.33;
    font-size: 18px;
    color: #000000;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.4;
    font-stretch: normal;
  }

  &__sms {
    flex: 1;
    justify-content: center;
    width: 250px;
  }

  &__error {
    margin-top: 4px;
    font-size: 12px;
    color: $red;
    text-align: center;
  }

  &__none {
    margin-top: 8px;
    font-size: 14px;
    color: $cerulean-blue;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__input {
    margin: auto;
    width: 100px !important;

    input[type=text] {
      font-size: 24px !important;
      text-align: center;
    }
  }
}
.document-preview-vital-list {
  display: flex;
  margin: 8px 0;
  max-width: 714px;

  &.--direction-row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.--direction-column {
    flex-direction: column;
  }

  &__title {
    font-family: SFProDisplay;
    line-height: 1.25;
    font-size: 16px;
    color: #333333;
  }

  &__elem {
    display: flex;
    margin: 12px 0;
    min-width: 311px;
  }

  &__name {
    display: block;
    max-width: 200px;
    line-height: 1.25;
    font-size: 14px;
    color: #333333;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  &__info {
    margin-left: 16px;
  }

  &__size {
    margin: 2px 0;
    font-family: SFProDisplay;
    font-size: 12px;
    color: #000000;
    opacity: 0.4;
  }

  &__download {
    font-size: 14px;
    color: #244a68;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__icon {
    position: relative;
    min-width: 43px;
    height: 56px;
    background-image: url("../../images/doc.svg");
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;

    &.--pdf {
      background-image: url("../../images/pdf.svg");
    }

    &.--excel {
      background-image: url("../../images/doc.svg");
    }

    &.--word {
      background-image: url("../../images/word.svg");
    }

    &.--removed {
      opacity: 0.3;
    }

    &__new {
      position: absolute;
      z-index: 10;
      top: -4px;
      right: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      min-width: 18px;
      height: 18px;
      font-family: SFProDisplay;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      background-color: #ec5a40;
      border: 2px solid #f7f7f7;
      border-radius: 18px;
      border-radius: 12px;
    }
  }
}
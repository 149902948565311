@import "../../styles/variables";

.request-task {
  position: relative;
  z-index: 1;
  margin-left: 100px;
  padding: 28px 0 0;
  width: 714px;
  max-width: 714px;

  &__statuses {
    display: flex;
    align-items: center;
  }

  &__status {
    margin-right: 20px;
  }
  
  &__id {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;

    &__label {
      font-family: SFProDisplay;
      font-size: 16px;
      color: $black;
      opacity: 0.4;
    }

    &__lang-switcher {
      margin-left: auto;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: bold;
    line-height: 1.12;
    font-size: 34px;
    color: $black;
    letter-spacing: normal;
    font-stretch: normal;
  }

  &__label {
    margin: 25px 0;
  }

  &__subheader {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 20px;
  }

  &__agreement {
    margin-top: 40px;
  }
}
.member {
  display: flex;
  padding: 70px 79px;

  &__text-block {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    width: 550px;
    max-width: 550px;

    &__name {
      margin-bottom: 13px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 31px;
      color: #222423;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &__company {
      margin-bottom: 16px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 0.94;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &__position {
      margin-bottom: 50px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1.29;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      opacity: 0.5;
      font-stretch: normal;
    }
    
    &__description {
      margin-bottom: 47px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1.53;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &__label {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      color: #000000;
      letter-spacing: normal;
      opacity: 0.4;
      font-stretch: normal
    }

    &__phone {
      margin-bottom: 33px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &__email {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      font-stretch: normal;
    }
  }
}

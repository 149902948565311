@import "../../styles/_variables";

.package {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &__title {
    margin-bottom: 17px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: bold;
    line-height: 1.21;
    font-size: 34px;
    color: $black;
    letter-spacing: 0.4px;
    text-align: center;
    font-stretch: normal;
  }
}

.three-dots-menu {
  position: relative;
  width: 38px;
  height: 38px;
  background-image: url("./images/three-dots.svg");
  background-position: center;
  background-size: 22px 5px;
  background-repeat: no-repeat;
  border-radius: 50%;

  &.--small {
    background-size: 15px 3px;
  }

  &.--active {
    z-index: 4000;
    background-color: #f7f7f7;
  }

  &:hover {
    cursor: pointer;
  }

  &__list {
    position: absolute;
    top: 53px;
    right: 0;
    box-shadow: 0 12px 30px 2px rgba(67, 67, 67, 0.1);

    &:before {
      position: absolute;
      top: -5px;
      right: 15px;
      display: block;
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #ffffff;
      border-left: 5px solid transparent;
      content: "";
    }
  }

  &__item {
    padding: 16px 16px;
    font-weight: 300;
    font-size: 16px;
    color: #757877;
    white-space: nowrap;
    background-color: #ffffff;

    &:hover {
      color: #ffffff;
      background-color: #abb6bf;
    }

    & + & {
      border-top: 1px solid rgba(0, 0, 0, 0.05);;
    }

  }
}
@import "../../styles/variables";

.members {
  display: flex;
  height: 100%;

  &__list {

  }

  &__view {
    display: flex;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 22px 0;
    height: 50px;
    min-height: 50px;
  }

  &__title {
    font-family: SFProDisplay;
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
    color: #333333;
    letter-spacing: normal;
  }

  &__searcher {
    padding: 25px 22px 22px;
  }

  &__overview {
    margin: 0;
    border-top: 1px solid;
    border-color: rgba(0, 0, 0, 0.05);

    a {
      text-decoration: none;
    }

    &_item {
      display: flex;
      align-items: center;
      padding: 11px 23px;
      font-family: SFProDisplay;
      color: #222423;
      cursor: pointer;


      &--active {
        display: flex;
        align-items: center;
        padding: 11px 23px;
        color: $white;
        background-color: $background-light-blue;

        .members__overview_company {
          color: $white;
          opacity: 0.6;
        }
      }
    }

    &__avatar {
      width: 48px;
      height: 48px;
      border-radius: 100%;
    }

    &_text {
      margin-left: 24px;
      width: 278px;
      font-size: 17px;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &_name {
      font-size: 17px;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &_company {
      font-size: 13px;
      color: #a1a6a3;
    }
  }
}

.preview {

  &__header {
    display: flex;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 17px;
    letter-spacing: -0.4px;
    font-stretch: normal;

    &_text {
      margin-right: 16px;
      color: #222423;
    }

    &_amount {
      color: #bdbdbd;
    }
  }

  &__items {
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    letter-spacing: normal;
    text-decoration: none;
    font-stretch: normal;

    &_icon {
      position: relative;
      margin-right: 12px;
      min-width: 21px;
      height: 28px;
      background-image: url("../../images/doc.svg");
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;

      &:hover {
        cursor: pointer;
      }

      &.--pdf {
        background-image: url("../../images/pdf.svg");
      }

      &.--excel {
        background-image: url("../../images/doc.svg");
      }

      &.--word {
        background-image: url("../../images/word.svg");
      }

      &.--removed {
        opacity: 0.3;
      }

      &__new {
        position: absolute;
        z-index: 10;
        top: -4px;
        right: -4px;
        display: flex;
        width: 12px;
        height: 12px;
        background-color: #ec5a40;
        border: 2px solid #f7f7f7;
        border-radius: 12px;
      }
    }

    a {
      color: #333333;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &_size, &_date {
      margin-left: 16px;
      padding-top: 3px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      color: #000000;
      letter-spacing: normal;
      text-align: right;
      white-space: nowrap;
      opacity: 0.4;
      font-stretch: normal;
    }

    &_download {
      margin-left: auto;
      min-width: 60px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1.43;
      font-size: 14px;
      color: #244a68 !important;
      letter-spacing: normal;
      text-align: right;
      font-stretch: normal;

      &:hover {
        cursor: pointer;
      }
    }

    &_delete {
      margin-left: auto;
      padding-left: 8px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1.43;
      font-size: 14px;
      color: #244a68;
      letter-spacing: normal;
      font-stretch: normal;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

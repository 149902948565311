@import "../../styles/variables";

.document-list-link {
  color: $black;
  text-decoration: none;

  &--active {
    color: $white;
    text-decoration: none;
    background-color: #3a6383;

    .document-list-el__text-block__date {
      color: #ffffff;
      opacity: 0.3;
    }
  }

  &.--read {

    .document-list-el__text-block__name, .document-list-el__user {
      font-weight: bold;
    }
  }
}

.document-list-el {
  position: relative;
  display: flex;
  padding: 15px 21px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &.--can-mark-unread {

    &:hover {

      .document-list-el__text-block__date {
        display: none;
      }

      .document-list-el__text-block__menu {
        display: block;
      }
    }
  }

  &__loading {
    margin: 0 auto;
  }

  &__avatar {
    position: relative;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__user {
    width: 184px;
    font-family: SFProDisplay;
    font-style: normal;
    line-height: normal;
    font-size: 17px;
    letter-spacing: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-stretch: normal;
    overflow: hidden;
  }

  &__text-block {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.--shift {
      margin-left: 20px;
    }

    &__date {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 13px;
      color: #a1a6a3;
      letter-spacing: -0.2px;
      font-stretch: normal;
    }

    &__menu {
      position: absolute;
      top: 8px;
      right: 16px;
      display: none;
    }

    &__name {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 13px;
      letter-spacing: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-stretch: normal;
    }

    &__statuses {
      display: flex;
      align-items: center;
      margin-top: 12px;
    }
  }

  &__unread {
    position: absolute;
    right: 20px;
    bottom: 16px;
    align-self: center;
    display: flex;
    align-items: center;

    &__icon {
      width: 17px;
      height: 18px;
      background-image: url("../../images/bubble-list.svg");
      background-size: contain;
      background-repeat: no-repeat;

      &.--active {
        background-image: url("../../images/bubble-list-active.svg");
      }
    }

    &__text {
      margin-left: 8px;
      padding-bottom: 3px;
      font-size: 13px;
      color: $cerulean-blue;

      &.--active {
        color: $white;
      }
    }
  }
}

.status {
  padding: 4px 8px;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 12px;
  color: #757877;
  letter-spacing: normal;
  text-align: center;
  background-color: #d8d8d8;
  border-radius: 2px;
  font-stretch: normal;
}

.loading {
  width: 46px;
  height: 46px;
  object-fit: contain;
  animation: spin 500ms infinite linear;
}

.filter-popover {

  &__content {
    padding: 8px;

    &__show-unread-only {
      display: flex;
      margin: 24px 0;
      user-select: none;
      cursor: pointer;

      &__label {
        margin-left: 8px;
      }
    }

    &__actions {
      display: flex;

      .button + .button {
        margin-left: 16px;
      }
    }
  }

  &__filter-button {
    margin: 8px 0;
    padding: 0 8px;
    cursor: pointer;
  }
}

.statuses {
  display: flex;
  flex-direction: column;

  &__label {
    margin: 8px 0;
    color: $grey-dark;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
  }

  &__list-item {
    display: flex;
    align-items: center;
    margin: 6px 0;
    user-select: none;
    cursor: pointer;

    &__check {
      margin-left: auto;

      &__img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
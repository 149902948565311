.profile {
  display: flex;
  margin-top: 12px;
  width: 438px;
  min-height: 353px;
  
  &__text-block {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    
    &__name {
      margin-bottom: 13px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 31px;
      color: #222423;
      letter-spacing: normal;
      font-stretch: normal;
    }
    
    &__company {
      margin-bottom: 13px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 0.94;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      font-stretch: normal;
    }
    
    &__position {
      margin-bottom: 40px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1.29;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      opacity: 0.5;
      font-stretch: normal;
    }
    
    &__label {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      color: #000000;
      letter-spacing: normal;
      opacity: 0.4;
      font-stretch: normal
    }
    
    &__phone {
      margin-bottom: 28px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      font-stretch: normal;
    }
    
    &__email {
      margin-bottom: 46px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 17px;
      color: #000000;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &__preferred-language {
      margin-bottom: 46px;
    }

    &__logout {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 17px;
      color: #e60101;
      letter-spacing: normal;
      cursor: pointer;
      font-stretch: normal;
    }
  }
}

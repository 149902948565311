.radio-group {
  
  &__item {
    margin-bottom: 16px;
    
    input[type="radio"] {
      cursor: pointer;
    }
    
    &-label {
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
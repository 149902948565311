@import "../../styles/_variables";

.alerts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px;
  min-width: 300px;
  max-width: 600px;
  font-family: SFProDisplay;
  color: $white;
  background-color: $cerulean-blue;
  border-radius: 4px;
  box-shadow: 0 9px 20px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;

  .close-button {
    margin-left: 20px;
    font-family: SFProDisplay;
    font-size: 14px;
    color: $white;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .warn-message {
    margin-left: 5px;
    color: #EC5A40;
    display: inline-block;
    vertical-align: top;
  }
  
  .warn-message-time {
    margin-left: 5px;
    display: inline-block;
    vertical-align: top;
    color: #FFFFFF;
    opacity: 0.4;
  }

}
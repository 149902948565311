.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: SFProDisplay;
  color: #000000;
  background-image: url("../../images/background.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &__panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    width: 415px;
    height: 545px;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.08);
    box-shadow: 0 16px 34px 0 rgba(0, 0, 0, 0.1);
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
      display: block;
      width: 54px;
      height: 54px;
      background-image: url("../../images/logo.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }

    &:after {
      display: block;
      margin-top: 20px;
      width: 99px;
      height: 8px;
      background-image: url("../../images/hermes.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    background-color: #f8f8f8;
    align-self: stretch;

    &_text {
      font-size: 16px;
      opacity: 0.5;
      cursor: pointer;
    }
  }
}

.support {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 328px;

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }

    &__header {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 30px;
      color: #222423;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &__sub-header {
      margin-top: 20px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 18px;
      color: #222423;
      letter-spacing: normal;
      text-align: center;
      opacity: 0.4;
      font-stretch: normal;
    }

    &__body {
      margin-top: 15px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      font-size: 22px;
      color: #222423;
      text-decoration: none;
      font-stretch: normal;
    }
  }

  &__icon {
    width: 56px;
    height: 56px;
    object-fit: contain;

    &--phone {
      background-image: url("../../images/phone.svg");
    }

    &--mail {
      background-image: url("../../images/mail.svg");
    }
  }
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 5px;

  &.--small {
    padding: 0 29px;
    height: 40px;
    font-size: 18px;
  }

  &.--normal {
    padding: 0 29px;
    height: 55px;
    font-size: 20px;
  }

  &.--big {
    min-width: 340px;
    height: 70px;
    font-family: SFProDisplay;
    font-style: normal;
    line-height: 0.91;
    font-size: 20px;
    letter-spacing: normal;
    font-stretch: normal;
  }

  &.--blue {
    background-color: #244a68;
  }

  &.--black {
    background-color: #111d26;
  }

  &.--red {
    background-color: #ec5a40;
  }

  &.--grey {
    color: #111d26;
    background-color: #e8eaec;
  }

  &.--link {
    color: #abb6bf;
    background-color: unset;
  }

  &:hover {
    cursor: pointer;
  }

  &__loading {
    position: absolute;
    width: 46px;
    height: 46px;
    object-fit: contain;
    animation: spin 500ms infinite linear;
  }

  &__content {

    &.--hidden {
      visibility: hidden;
    }
  }
}

@keyframes spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@import "../../styles/_variables";

.comments-block {

  &__single_block_input {
    margin-top: 32px;
    margin-bottom: 32px;
    font-size: 20px;
  }

  &__multiple_block_label {
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 17px;
    color: $black;
    letter-spacing: normal;
    font-stretch: normal;
  }
}
.documents-header {
  padding: 28px 0 12px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &__text {
    font-family: SFProDisplay;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
  }

  &__clean {
    padding: 8px 0;
    width: 100%;
    background-color: #e1e1e1;
  }

  &__thin {
    padding-top: 12px;
    padding-bottom: 13px;
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
  }
}
.mobile-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:before {
      display: block;
      width: 121px;
      height: 121px;
      background-image: url("../../images/logo.svg");
      background-size: contain;
      content: "";
    }

    &:after {
      display: block;
      margin-top: 44px;
      width: 218px;
      height: 16px;
      background-image: url("../../images/hermes.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
    }
  }

  &__title {
    margin: 30px 0 132px;
    width: 222px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    font-size: 16px;
    color: #121d26;
    letter-spacing: 0.09px;
    text-align: center;
    font-stretch: normal;
  }

  &__bitmap {
    width: 64px;
    height: 64px;
    background-image: url("./images/bitmap.png");
  }

  &__invite {
    margin: 28px 0;
    width: 254px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    font-size: 16px;
    color: #121d26;
    letter-spacing: 0.09px;
    text-align: center;
    font-stretch: normal;
  }

  &__apple {
    width: 182px;
    height: 62px;
    background-image: url("./images/apple.png");

    &.--en {
      width: 160px;
      height: 54px;
      background-image: url("./images/apple_en.png");
      background-position: center;
      background-size: cover;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
@import "../../styles/_variables";

.discussion {
  width: 100%;
  height: 100%;

  &__messages {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 255px;
    max-height: 50vh;
    overflow: auto;

    &__no-messages, &__loading-message {
      margin: auto 0;
      font-family: SFProDisplay;
      font-weight: normal;
      font-size: 16px;
      color: $black;
      opacity: 0.3;
      align-self: center
    }

    &__new-messages {
      margin: 16px 0;
      width: 100%;
      font-family: SFProDisplay;
      font-weight: normal;
      font-size: 16px;
      color: $black;
      text-align: center;
      background-color: $grey-background;
      opacity: 0.3;
      align-self: center;
    }
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $grey-background;

    &__refresh {
      position: absolute;
      top: -40px;
      right: 16px;
      width: 28px;
      height: 28px;
      background-image: url("../../images/refresh.svg");
      background-size: contain;
      background-repeat: no-repeat;

      &:hover {
        cursor: pointer;
      }
    }

    &__message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 14px 0;

      &__toolbox {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__attach {
          margin: 0 16px;
          width: 17px;
          height: 17px;
          background-image: url("../../images/attach.svg");
          background-size: contain;
          background-repeat: no-repeat;

          &:hover {
            cursor: pointer;
          }
        }

        &__input {
          height: 38px;

          & input {
            padding-right: 16px !important;
            padding-left: 16px !important;
            background: $grey-input-background;
            border-radius: 14px;
          }

          & .input__counter, .input__line {
            display: none;
          }
        }

        &__send {
          margin: 0 16px;
          width: 46px;
          height: 46px;
          background-image: url("../../images/send.svg");
          background-size: contain;
          background-repeat: no-repeat;

          &:hover {
            cursor: pointer;
          }

          &.is-disabled {
            opacity: 0.3;
          }
        }
      }
    }

    &__dropzone {
      margin: 24px 24px;
    }
  }
}

.message {
  margin: 16px 16px;
  font-family: SFProDisplay;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__user {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__username {
      margin: 0 8px;
      font-weight: bold;
      font-size: 15px;
      color: $cerulean-blue;
    }

    &__status {
      margin: 0 8px;
      font-weight: bold;
      font-size: 14px;

      &.is-FINAL_APPROVED, &.is-APPROVED {
        color: $green;
      }

      &.is-FINAL_REJECTED, &.is-REJECTED {
        color: $red;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__text {
    margin-left: 38px;
    font-size: 14px;
    color: $black;
    word-break: break-word;
  }

  &__date {
    margin-left: 8px;
    font-size: 12px;
    color: $grey-normal;
  }

  &__documents {
    margin-top: 16px;
    margin-left: 38px;
  }
}

.documents {
  margin-top: 8px;
  margin-bottom: 8px;

  &__document {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;

    &__icon {
      min-width: 21px;
      height: 28px;
      background-image: url("../../images/doc.svg");
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;

      &:hover {
        cursor: pointer;
      }

      &--pdf {
        background-image: url("../../images/pdf.svg");
      }

      &--excel {
        background-image: url("../../images/doc.svg");
      }

      &--word {
        background-image: url("../../images/word.svg");
      }
    }

    &__filename {
      margin: 0 8px;
      max-width: 60ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }
    }

    &__filesize {
      margin: 0 8px;
      font-size: 12px;
      color: $black;
      opacity: 0.4;
    }

    &__download {
      margin: 0 8px;
      color: $cerulean-blue;
      text-decoration: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.discussion-popup {
  position: fixed;
  z-index: 5000;
  right: 24px;
  bottom: 0;
  width: 30vw;
  min-width: 350px;
  background-color: $white;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0 -1px 12px 0 rgba(0, 0, 0, 0.07);

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;
    height: 52px;
    background-color: $cerulean-blue;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &__icon {
    margin: 0 8px;
    width: 21px;
    height: 20px;
    background-image: url("../../images/bubble-contrast.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__title {
    margin: 0 8px;
    padding-bottom: 2px;
    font-family: SFProDisplay;
    font-weight: bold;
    font-size: 20px;
    color: $white;
  }

  &__container {
    position: relative;
  }

  &__shutter {
    position: absolute;
    top: 19px;
    right: 20px;
    width: 14px;
    height: 14px;
    background-image: url("../../images/x-contrast.svg");
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }
}
.container {

  & + & {
    margin-top: 41px;
  }
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 111px;
  text-align: center;
  cursor: pointer;

  &__border {
    border: 1px dashed #b2b2b2;
    border-radius: 6px;

    &.--error {
      border: 1px dashed #ec5a40;
    }
  }

  &__section {
    position: relative;
    margin-top: 10px;
  }

  &__uploading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);

    &:hover {
      cursor: default;
    }
  }

  &__text {
    color: #8c8c8c;
  }

  &__list {
    overflow: auto;
  }

  div {

    &:focus {
      outline: none;
    }
  }

  &__header {
    margin-bottom: 18px;

    &__title {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 17px;
      color: #222423;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &__count {
      margin-left: 15px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1.25;
      font-size: 16px;
      color: #333333;
      letter-spacing: normal;
      opacity: 0.4;
      font-stretch: normal;
    }
  }

  &__file {
    display: flex;
    align-items: center;
    padding: 14px 12px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 6px;

    & + & {
      margin-top: 10px;
    }


    &__name {
      margin-left: 10px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: 1.25;
      font-size: 16px;
      color: #333333;
      letter-spacing: normal;
      word-break: break-word;
      font-stretch: normal;
    }

    &__size {
      margin-left: auto;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 12px;
      color: #000000;
      letter-spacing: normal;
      text-align: right;
      white-space: nowrap;
      opacity: 0.4;
      font-stretch: normal;
    }

    &__close {
      margin-left: 25px;
      cursor: pointer;
    }
  }
}

// colors
$cerulean-blue: #111D26;
$black: #222423;
$grey-normal: #a1a6a3;
$grey-dark: #757877;
$white: #ffffff;
$red: #ec5a40;
$green: #68a526;
$dark: #111d26;
$dark-active: #293239;
$grey-background: #d8d8d8;
$grey-input-background: #f7f7f7;
$background-light-blue: #3a6383;
$tab-label-active: #000000;
$tab-label: #abb6bf;

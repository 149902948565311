@import "../../styles/variables";

.decision-business-status-label {
  display: inline-block;
  font-family: SFProDisplay;
  color: #ffffff;
  border-radius: 10.5px;

  &.--REJECTED,
  &.--FINAL_REJECTED,
  &.--SKIPPED,
  &.--EXPIRED {
    background-color: #ec5a40;
  }

  &.--APPROVED,
  &.--EXECUTED,
  &.--FINAL_APPROVED,
  &.--RESPONSE_PREPARED {
    background-color: #68a526;
  }

  &.--APPROVING,
  &.--REQUESTED,
  &.--REWORKING,
  &.--EXECUTING,
  &.--RETURNED_FOR_REWORKING,
  &.--FINAL_REQUESTED,
  &.--APPROVED.--ROLE_APPROVER,
  &.--APPROVED.--ROLE_INITIATOR {
    background-color: #abb6bf;
  }

  &.--MY_REQUESTED,
  &.--MY_RESPONSE,
  &.--MY_APPROVING {
    color: #000000;
    background-color: #ffb600;
  }

  &.--DRAFT,
  &.--UNDEFINED,
  &.--DELETED {
    color: #757877;
    background-color: #ffffff;
    border: solid 1px rgba(0, 0, 0, 0.24);
  }

  &.--view {
    padding: 4px 7px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-stretch: normal;
  }

  &.--list {
    padding: 4px 9px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 12px;
    letter-spacing: normal;
    font-stretch: normal;
  }
}

.urgent-approve-label {
  display: inline-block;
  font-family: SFProDisplay;
  
  &__text {
    margin-left: 6px;
  }
  
  &.--view {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 15px;
    color: $red;
  }

  &.--list {
    margin-left: 11px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 12px;
    letter-spacing: normal;
    font-stretch: normal;
  }
}

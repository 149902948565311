@import "../../styles/variables";

$left-padding: 46px;

.coordination {
  margin: 40px 0;

  &__agreement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &_header {
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-size: 20px;
      color: $black;
      letter-spacing: normal;
      font-stretch: normal;
    }
  }

  &__level {
    padding: 10px 24px 10px 13px;
    border-radius: 6px;

    &.--initiator {
      margin-bottom: 28px;
      padding-left: 0;
    }

    &.--waiting {

      .coordination__level_name {
        opacity: 0.4;
      }
    }

    &.--final-approver, &.--executor {
      margin-bottom: 28px;
      background-color: #f7f7f7;

      .coordination__comment {
        font-size: 17px;
      }
    }

    &.--approver {
      background-color: #f7f7f7;

      & + & {
        margin-top: 2px;
      }
    }

    &_user {
      display: flex;
      flex: 1.6;
      align-items: center;
      cursor: pointer;
    }


    &_photo {
      margin-right: 16px;
    }

    &_name {
      margin-right: 16px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      color: $black;
      letter-spacing: normal;
      font-stretch: normal;
    }

    &_status {
      flex: 1;
      margin-left: 16px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #bcbcbc;
      letter-spacing: normal;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-stretch: normal;
      overflow: hidden;

      &.--APPROVED,
      &.--FINAL_APPROVED,
      &.--EXECUTED,
      &.--RESPONSE_PREPARED {
        color: $green;
      }

      &.--REJECTED,
      &.--FINAL_REJECTED,
      &.--SKIPPED,
      &.--EXPIRED,
      &.--RETURNED_FOR_REWORKING {
        color: $red;
      }

      &.--AWAITING_EXECUTION {
        display: none;
      }
    }

    &_date {
      flex: 1;
      margin: 0 8px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 14px;
      color: $grey-normal;
      letter-spacing: normal;
      font-stretch: normal;

      &.--long {
        flex: 2.5;
      }
    }

    &_days_spent {
      flex: 0.8;
      text-align: right;
    }

    &_update-decision-comment {
      display: flex;
      align-items: center;
      margin: 0 16px;

      &__icon {
        min-width: 9px;
        height: 9px;
        background-image: url("../../images/edit.svg");
        background-position: left center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &__text {
        margin: 0 8px;
        font-family: SFProDisplay;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $cerulean-blue;
        letter-spacing: normal;
        font-stretch: normal;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__comment {
    margin: 8px 0;
    padding-left: $left-padding;
    font-style: normal;
    font-weight: normal;
    line-height: 1.43;
    font-size: 17px;
    font-stretch: normal;
    word-break: break-word;

    &.--approver, &.--final-approver, &.--executor {
      font-size: 14px !important;
    }
  }

  &__files_container {
    padding-left: $left-padding;
    color: #333333;
  }

  &__files_vital {
    display: flex;
    justify-content: space-between;
  }

  &__files_removed {
    margin-top: 16px;
    margin-bottom: 20px;
  }

  &__files_add {
    margin-bottom: 16px;
  }

  &__files_more {
    margin-top: 8px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $cerulean-blue;
    letter-spacing: normal;
    font-stretch: normal;

    &.--final-approver, &.--executor {
      margin-bottom: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__collapse {
    margin-top: 8px;
    font-size: 14px;
    color: $cerulean-blue;

    &:hover {
      cursor: pointer;
    }
  }
  
  &__approval-type {
    padding-left: $left-padding;
  }
}

.modal-window {
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;

  &__container {
    position: relative;
    margin: auto;
    padding: 46px 56px 46px 46px;
    background-color: #ffffff;
    box-shadow: 0 16px 34px 0 rgba(0, 0, 0, 0.1);
  }

  &__shutter {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 21px;
    height: 21px;
    background-image: url("../../images/x.svg");
    background-size: contain;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }
}

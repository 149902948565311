.no-match {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-image: url("../../images/group-3.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  
  &__title {
    margin-bottom: 17px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: bold;
    line-height: 1.21;
    font-size: 34px;
    color: #000000;
    letter-spacing: 0.4px;
    text-align: center;
    font-stretch: normal;
  }
  
  &__text {
    margin-bottom: 36px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
    font-size: 15px;
    color: #222423;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.62;
    font-stretch: normal;
  }
  
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 50px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 0.91;
    font-size: 22px;
    color: #ffffff;
    letter-spacing: normal;
    text-align: center;
    text-decoration: none;
    background-color: #244a68;
    border-radius: 5px;
    cursor: pointer;
    font-stretch: normal;
  }
}

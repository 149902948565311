@import "../../styles/variables";

.members-search {
  position: relative;

  &.--z-index100 {
    z-index: 100;
  }

  &__container {
    position: absolute;
    top: 48px;
    right: 0;
    left: 0;
    padding: 0;
    box-shadow: 0 24px 32px -9px rgba(0, 0, 0, 0.22);
  }

  &__input {
    position: relative;
    display: flex;
    margin-bottom: 24px;
    width: 100%;
    height: 48px;
    font-family: SFProDisplay;

    .label {
      position: absolute;
      top: 16px;
      left: 0;
      font-family: SFProDisplay;
      font-size: 16px;
      color: $grey-normal;
      transform-origin: 0 0;
      transition: all 0.2s ease;
    }

    .border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      color: $grey-normal;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: all 0.15s ease;
    }

    input {
      z-index: 99;
      padding: 8px 0;
      width: 100%;
      height: 43px;
      font-family: SFProDisplay !important;
      font-size: 16px;
      color: $black;
      background: none;
      border: 0;
      border-bottom: 1px solid $grey-normal;
      border-radius: 0;
      -webkit-appearance: none;
      transition: all 0.15s ease;

      &:not(:placeholder-shown) {

        + span {
          color: $grey-normal;
          transform: translateY(-26px) scale(0.75);
        }
      }

      &:focus {
        background: none;
        outline: none;

        + span {
          color: $grey-normal;
          transform: translateY(-26px) scale(0.75);

          + .border {
            transform: scaleX(1)
          }
        }
      }
    }
  }

  &.--error {

    input {
      border-color: #ec5a40;
    }
  }
}

.option {
  display: grid;
  align-items: center;
  padding: 8px 20px;
  grid-template-columns: 1fr max-content;
  
  .check {
    display: none;
  }
  
  &__selected {
    background: #F7F7F7;

    .check {
      display: block;
      margin-right: 5px;
      width: 24px;
      height: 24px;
      color: #00B956;
    }
  }
}

.optionLabel {
  display: flex;
  align-items: center;
  
  & > div {
    &:first-child {
      margin-right: 20px;
    } 
  }
}
.loader {
  background-image: url("./images/loader.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: loader 0.6s linear infinite;
}

@keyframes loader {

  to {
    transform: rotate(360deg);
  }
}

@import "../../styles/variables";

.not-viewed {
  position: absolute;
  z-index: 10;
  width: 10px;
  height: 10px;
  background-color: $red;
  border: 1px solid #f8f8f8;
  border-radius: 50%;
}
@import "../../../styles/variables";

.auth-pin-create {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &__header {
    margin: 47px 0 9px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 30px;
    color: #000000;
    letter-spacing: normal;
    text-align: center;
    font-stretch: normal;
  }

  &__text {
    margin-bottom: 83px;
    width: 246px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.33;
    font-size: 18px;
    color: #000000;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.4;
    font-stretch: normal;
  }

  &__hint {
    flex: 1;
    margin-top: 53px;
    width: 320px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
    font-size: 16px;
    color: #a1a6a3;
    letter-spacing: normal;
    text-align: center;
    font-stretch: normal;
  }
}

.auth-pin-create-repeat {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &__text {
    margin: 92px 0 107px;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.33;
    font-size: 18px;
    color: #000000;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.4;
    font-stretch: normal;
  }

  & + .auth__bottom {
    display: none;
  }

  &.--error {

    .auth-pin-dot {
      background-color: $red;
    }
  }

  &__error {
    margin-top: 53px;
    font-family: SFProDisplay;
    font-size: 16px;
    color: $red;
  }
}

@import "../../styles/variables";

.work_label {
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: $grey-normal;
  letter-spacing: normal;
  font-stretch: normal;
}
@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Semibold.otf");
  font-weight: 500;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "SFProDisplay";
  src: url("../fonts/SFProDisplay-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "SFProText";
  src: url("../fonts/SFProText-Bold.ttf");
  font-weight: 700;
}
@import "variables";

.data-list {
  display: flex;
  flex-direction: column;
  width: 391px;
  min-width: 391px;
  height: 100%;
  background-color: #f3f3f3;
  overflow-y: auto;
}

.data-view {
  padding: 58px 98px;
  max-height: 100%;
  overflow: auto;
}

.data-file {
  display: flex;
  align-items: center;
  font-family: SFProDisplay;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: $cerulean-blue;
  letter-spacing: normal;
  font-stretch: normal;

  &:before {
    display: block;
    margin-right: 10px;
    width: 17px;
    height: 17px;
    background-image: url("../images/attach.svg");
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
  }

  &:hover {
    cursor: pointer;
  }
}

@import "../../styles/variables";

.watchers-list {
  margin-top: 66px;

  &__header {
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: 20px;
    letter-spacing: normal;
    font-stretch: normal;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    color: $cerulean-blue;

    & > div {
      min-width: 33%;
    }

    &:after {
      content: "";
      flex: 1;
    }

    &.--collapsed {

      &:after {
        display: none;
      }
    }
  }

  &__watcher {
    display: inline-flex;
    align-items: center;
    margin: 24px 0 0;
    white-space: nowrap;
  }

  &__photo {
    margin-right: 16px;
  }

  &__new {
    display: inline-flex;
    align-items: center;
    margin: 24px 0 0;
    font-family: SFProDisplay;
    font-style: normal;
    font-weight: normal;
    line-height: 1.29;
    font-size: 17px;
    letter-spacing: normal;
    font-stretch: normal;

    &:hover {
      cursor: pointer;
    }
  }

  &__plus {
    display: flex;
    justify-content: center;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 20px;
    background-color: #f7f7f7;
    border-radius: 50%;
  }

  &__modal {
    width: 500px;

    &_header {
      margin-bottom: 50px;
      font-family: SFProDisplay;
      font-style: normal;
      font-weight: bold;
      line-height: 1.21;
      font-size: 34px;
      color: $black;
      letter-spacing: 0.41px;
      font-stretch: normal;
    }
  }

  &__modal_shift {
    transform: translate3d(0, -25%, 0);
  }
}
.empty-list-documents {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: SFProDisplay;
  color: #adadad;

  &.--singleList {
    padding-top: 66px;
    padding-bottom: 77px;
    border-bottom: 1px solid #e1e1e1;
  }

  &__header {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #959ca3;
    letter-spacing: normal;
    text-align: center;
    font-stretch: normal;

    &.--singleList {
      margin-top: 24px;
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  &__text {
    width: 258px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.38;
    font-size: 14px;
    text-align: center;
    font-stretch: normal;
  }

  &__img {
    width: 91px;
    height: 91px;
    background-image: url("../../images/inbox-grey.svg");
    background-size: contain;
    background-repeat: no-repeat;

    &.--singleList {
      width: 71px;
      height: 91px;
      background-image: url("../../images/docs-placeholder.svg");
    }
  }
}
@import "../../styles/_variables";

.new-task {
  overflow: auto;
  position: relative;
  z-index: 0;
  max-height: calc(100% - 54px);

  &__view {
    display: flex;
    flex-direction: column;
    padding: 0 48px 75px;
    width: 890px;
    max-width: 890px;
    height: 100%;

    &__form {
      padding-top: 16px;
      height: auto;
    }
  }

  &__exetutor {
    position: relative;
    z-index: 1000;
  }

  &__one-for-each {
    display: flex;
    margin-top: -16px;
    margin-bottom: 24px;

    &__labels {
      display: flex;
      flex-direction: column;

      &__title {
        font-family: SFProDisplay;
        font-weight: normal;
        font-size: 15px;
        color: $dark;
      }

      &__subtitle {
        font-family: SFProDisplay;
        font-weight: normal;
        font-size: 12px;
        color: $grey-normal;
      }
    }
  }
}

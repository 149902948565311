.logout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 46px 0;
  height: 100%;
  background-image: url("../../images/background.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &__logo {
    width: 89px;
    height: 89px;
    background-image: url("../../images/logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    order: 1;
  }

  &__hermes {
    margin-top: 36px;
    width: 218px;
    height: 16px;
    background-image: url("../../images/hermes.svg");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    order: 2;
  }

  &__message {
    margin-top: 36px;
    font-family: SFProText;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #ffffff;
    order: 3;
    font-stretch: normal;
  }

  &__redirect-message {
    margin-top: 36px;
    font-family: SFProText;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    order: 3;
    font-stretch: normal;
  }
}
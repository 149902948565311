.no-document-img {
  width: 160px;
  height: 117px;
  background-image: url("../../images/arrow.svg");
  object-fit: contain;
}

.no-document-text {
  margin: 20px 0 0 130px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 17px;
  letter-spacing: 0.1px;
  font-stretch: normal;
}

.no-selected-document {
  padding: 219px 0 0 30px;
}

.no-loaded-documents {
  padding: 37px 0 0 30px;
}